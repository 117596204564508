import { Component, OnInit } from '@angular/core';
import { SchulteTableService } from '../schulte-table.service';
import { TableSpec } from './table-spec';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Observable, interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-schulte-table',
  templateUrl: './schulte-table.component.html',
  styleUrls: ['./schulte-table.component.sass'],
  animations: [
    trigger('unpressedPressed', [
      state('unpressed', style({
        backgroundColor: 'transparent'
      })),
      state('pressed', style({
        backgroundColor: 'green'
      })),
      transition('unpressed => pressed', [
        animate('0.1s')
      ]),
      transition('pressed => unpressed', [
        animate('0.1s')
      ])
    ])
  ]
})
export class SchulteTableComponent implements OnInit {
  table: TableSpec = {
    rows: [],
    max: 0
  };
  current: number;


  timeSpent: string;

  animate: boolean;
  timer: Subscription;

  constructor(private schulteService: SchulteTableService) { }

  ngOnInit(): void {
    this.getTable(9);
  }

  getTable(size: number): void {
    if (this.timer != null) {
      this.timer.unsubscribe();
    }

    this.schulteService.getTable(size)
      .subscribe(table => {
        this.table = table;
        this.timer = interval(1000)
          .subscribe(t => this.timeSpent = t.toString());
        this.current = 1;
        this.timeSpent = '';
      });
  }

  numPressed(id: number, row: number, col: number) {
    if (id == this.current) {
      this.animate = true;
      this.current++;
    }

    if (this.current == this.table.max + 1) {
      this.timer.unsubscribe();
    }

    this.schulteService.cellOpened(row, col);
  }

}
