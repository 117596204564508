import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FiguresComponent } from './figures/figures.component';
import { SchulteTableComponent } from './schulte-table/schulte-table.component';
import { merge } from 'rxjs';


const routes: Routes = [
  { path: 'figures', component: FiguresComponent },
  { path: 'schulte-table', component: SchulteTableComponent },

  { path: '', redirectTo: '/figures', pathMatch: 'full' },
  { path: '**', redirectTo: '/figures', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
