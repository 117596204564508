import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FiguresComponent } from './figures/figures.component';
import { from } from 'rxjs';
import { SchulteTableComponent } from './schulte-table/schulte-table.component';
import { StudentSchulteTableComponent } from './student-schulte-table/student-schulte-table.component';

@NgModule({
  declarations: [
    AppComponent,
    FiguresComponent,
    SchulteTableComponent,
    StudentSchulteTableComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
