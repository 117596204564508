import { Component, OnInit } from '@angular/core';
import { FiguresSpec } from './figure_spec';
import { FigureType } from './figure_type';

@Component({
  selector: 'app-figures',
  templateUrl: './figures.component.html',
  styleUrls: ['./figures.component.sass']
})
export class FiguresComponent implements OnInit {

  figures: FigureType[] = [
    { enabled: true, name: 'SQUARE' },
    { enabled: true, name: 'RECTANGLE' },
    { enabled: true, name: 'CIRCLE' },
    { enabled: true, name: 'ELLIPSE' },
    { enabled: true, name: 'LINE' },
    { enabled: true, name: 'TRIANGLE' }
  ];

  figuresSpec: FiguresSpec = {
    count: 3,
    width: 500,
    height: 500,
    border: true,
    rotate: true
  };

  imagePath = 'api/v1/figures';
  imageHidden = false;

  ngOnInit() {
    this.calculatePath();
  }

  constructor() { }

  onSubmit() {
    this.calculatePath();
  }

  calculatePath() {
    var newImage = `api/v1/figures?count=${this.figuresSpec.count}` +
      `&border=${this.figuresSpec.border}&rotate=${this.figuresSpec.rotate}` +
      `&width=${this.figuresSpec.width}&height=${this.figuresSpec.height}`;

    this.figures.forEach(f => {
      if (f.enabled) {
        newImage += `&figures=${f.name}`;
      }
    });

    this.imagePath = newImage + `&ts=${new Date().getTime()}`;
  }

  hideSwitch() {
    this.imageHidden = !this.imageHidden;
  }

}
