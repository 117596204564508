import { Injectable } from '@angular/core';
import { TableSpec } from './schulte-table/table-spec';
import { Observable, of, from, PartialObserver, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import { TableCommand, TableCommandType, LoadTableCommand, CellOpenedTableCommand } from './table-command';
import { Cell } from './cell';

@Injectable({
  providedIn: 'root'
})
export class SchulteTableService {
  private _ws: WebSocketSubject<TableCommand>;
  private _table: Subject<TableSpec>;
  private _cellOpened: Subject<Cell>;

  constructor(private http: HttpClient) {
    this._table = new Subject<TableSpec>();
    this._ws = webSocket(environment.wsUrl);
    this._ws.subscribe({
      next: (command) => this.processCommand(command),
      error: (err) => console.log(err),
      complete: () => console.log("WS DONE")
    });
  }

  getTable(size: number): Observable<TableSpec> {
    return this.http.get<TableSpec>(`api/v1/schulte?size=${size}`)
  }

  cellOpened(row: number, col: number) {
    const cell : Cell = {
      row: row,
      col: col
    };

    const command = new CellOpenedTableCommand(cell)
    this._ws.next(command)
  }

  private processCommand(command: TableCommand) {
    console.log(`Received command ${command}`);
    switch (command.type) {
      case TableCommandType.LOAD_TABLE: {
        this._table.next((command as LoadTableCommand).table);
      }
      case TableCommandType.CELL_OPENED: {
        this._cellOpened.next((command as CellOpenedTableCommand).cell);
      }
      default: {
        console.log(`No processing for ${command}`);
      }
    }
  }
}
