<div class="row">
    <div class="col">
        <h1 class="display-4" [hidden]="current > table.max">Find {{current}}</h1>
        <h1 class="display-4" [hidden]="current < table.max + 1">Congratulations!</h1>
    </div>
    <div class="col">
        <h1 class="display-4">Time spent: {{timeSpent}} sec.</h1>
    </div>
</div>

<div class="row">
    <div class="col">
        <table class="schulte-table">
            <tr *ngFor="let row of table.rows; let rowNum = index">
                <td *ngFor="let cell of row.cells; let colNum = index" (click)="numPressed(cell.text, rowNum, colNum)"
                    [@unpressedPressed]="(cell.text == current - 1) && animate ? 'pressed' : 'unpressed'"
                    (@unpressedPressed.done)="animate = false">{{cell.text}}</td>
            </tr>
        </table>
    </div>
    <div class="col-md-auto">
        <div class="btn-group-vertical">
            <button type="button" class="btn btn-secondary" (click)="getTable(9)">9</button>
            <button type="button" class="btn btn-secondary" (click)="getTable(16)">16</button>
            <button type="button" class="btn btn-secondary" (click)="getTable(25)">25</button>
            <button type="button" class="btn btn-secondary" (click)="getTable(36)">36</button>
        </div>
    </div>
</div>