<div class="row">
    <div class="col">
        <form (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col">
                    <!-- count -->
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Count</span>
                        </div>
                        <input type="number" class="form-control" placeholder="Count" [(ngModel)]="figuresSpec.count"
                            name="count">
                    </div>

                    <div class="input-group mb-3">
                        <!-- width -->
                        <div class="input-group-prepend">
                            <span class="input-group-text">Width</span>
                        </div>
                        <input type="number" class="form-control" placeholder="Width" [(ngModel)]="figuresSpec.width"
                            name="width">

                        <!-- height -->
                        <div class="input-group-prepend">
                            <span class="input-group-text">Height</span>
                        </div>
                        <input type="number" class="form-control" placeholder="Height" [(ngModel)]="figuresSpec.height"
                            name="height">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <!-- border -->
                    <div class="input-group mb-3">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="borderSwitch"
                                [(ngModel)]="figuresSpec.border" name="border">
                            <label class="custom-control-label" for="borderSwitch">Border</label>
                        </div>
                    </div>

                    <div class="input-group mb-3">
                        <!-- rotate -->
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="rotateSwitch"
                                [(ngModel)]="figuresSpec.rotate" name="rotate">
                            <label class="custom-control-label" for="rotateSwitch">Rotate</label>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <!-- figures -->
                    <div *ngFor="let figure of figures" class="input-group mb-3">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="{{figure.name}}Switch"
                                [(ngModel)]="figure.enabled" name="{{figure.name}}">
                            <label class="custom-control-label" for="{{figure.name}}Switch">{{figure.name}}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <!-- buttons -->
                    <div class="btn-group" role="group" aria-label="Control">
                        <button type="submit" class="btn btn-primary" [disabled]="imageHidden">Generate</button>
                        <button type="button" class="btn btn-secondary" (click)="hideSwitch()">Hide</button>
                    </div>
                </div>
            </div>
        </form>
    </div>


    <div class="col">
        <img [src]="imagePath" class="float-right img-thumbnail" [hidden]="imageHidden">
    </div>
</div>