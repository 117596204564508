<header>
    <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark navbar-default">
        <a class="navbar-brand" href="#">{{title}}</a>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/figures" routerLinkActive="active">Figures</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/schulte-table" routerLinkActive="active">Schulte tables</a>
                </li>
            </ul>
        </div>
    </nav>
</header>
<div class="container">
    <router-outlet></router-outlet>
</div>
