import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  title = 'Fast Reading';

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    const url = new URLSearchParams(new URL(document.URL).search);
    if (url.has('redirect')) {
      const redirect = url.get('redirect');
      console.log(`Route ${redirect}`);
      this.router.navigate([redirect]);
    }
  }
}
