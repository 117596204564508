import { TableSpec } from './schulte-table/table-spec';
import { Cell } from './cell';

export abstract class TableCommand {
    type: TableCommandType;

    constructor(type: TableCommandType) {
        this.type = type;
    }
}

export enum TableCommandType {
    LOAD_TABLE,
    CELL_OPENED,
    ALL_CELLS_OPENED,
    TIME_FINISHED,
    STUDENT_CONNECTED,
    STUDENT_DISCONNECTED
}

export class LoadTableCommand extends TableCommand {
    table: TableSpec;

    constructor(table: TableSpec) {
        super(TableCommandType.LOAD_TABLE);
        this.table = table;
    }
}

export class CellOpenedTableCommand extends TableCommand {
    cell: Cell;

    constructor(cell: Cell) {
        super(TableCommandType.CELL_OPENED);
        this.cell = cell;
    }
}

export class AllCellsOpenedTableCommand extends TableCommand {
    name: string;

    constructor() {
        super(TableCommandType.ALL_CELLS_OPENED);
    }
}

export class TimeFinishedTableCommand extends TableCommand {
    name: string;

    constructor() {
        super(TableCommandType.TIME_FINISHED);
    }
}

export class StudentConnectedTableCommand extends TableCommand {
    name: string;

    constructor() {
        super(TableCommandType.STUDENT_CONNECTED);
    }
}

export class StudentDisconnectedTableCommand extends TableCommand {
    name: string;

    constructor() {
        super(TableCommandType.STUDENT_DISCONNECTED);
    }
}